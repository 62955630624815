import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Dictionary, ViewModes } from 'src/app/shared/models/global';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';


@Injectable({
  providedIn: 'root'
})
export class TvLoginGuard {
  constructor(
    private authService: AuthService,
    private appState: AppStateService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const code = route.params.code;
    if (!code) {
      return this.router.parseUrl('/auth/login');
    }

    // validate code
      // if code exists and not used

        // create a tv user in cognito, linked to the facility of the authorizing entity.
        // create a custom Auth token and add that to the cognito user
        // send push subcription event  to tv (client) api with permission with username and customAuth token
        // !! only the client with the accesstoken should be able to receive the subscription event
      // else return false

    return true;
  }
}